@import "sass-lib";
@media (min-width: 0px) and (max-width: 1279px) {
	.wrapper{ width: auto; margin: 0 50px; }

	header{
		.main-head{
			.wrapper{
				nav{
					ul{
						li{
							&:nth-child(2){ margin-right: 40px; }
							&:nth-child(4){ margin-left: 40px; }
							> a{ font-size: 14px; padding: 34px 15px; }
							&.hv_child{
								ul{ }
								>a{ padding-right: 20px; }
							}
						}
					}
				}
				.rh_head{
					.src{
						.form-search{
							input[type='text']{  }
						}
					}
				}
			}
		}

	}
	.tabbing-spek{
		.btn-tab{ position: relative; margin-bottom: 50px;  }
		.box-desc-type{ padding-top:0; }
		.slide-type{
			.border-bg{ height: 350px; }
		}

	}


	.ttl{ font-size: 24px; line-height:32px; }
	.home-slide-section{
		&.home{ height: 100vh; position: relative; }
		.slider-home{
			.slide-banner{
				.caption-text{
					.txt{
						h2{ font-size:38px;  }
						p{ font-size: 14px; line-height: 22px; }
					}
				}
			}
		}

	}
	.more{ font-size: 14px; }
	.three-box{
		margin: 50px 0 0;
		figure{ height: 210px; }
		&.newshome{
			figure{ height: auto;}
		}
		.list{ margin-bottom: 80px; }
		h4{ font-size: 20px; line-height: 30px; margin-bottom: 10px;}
		.text{ padding:15px; }
		p{ font-size: 14px; line-height: 20px; max-height: 60px; }
		.more{ margin-right: 0; }
	}
	.scrollify{
		height: auto; overflow: unset;
	}

	.home{
		&.about{
			padding:60px 0 100px;
			.wrapper{ top: auto; @include transform(translateY(0)); }
		}
		&.newsevent{
			padding: 70px 0 50px;
		}
		&.benefit{
			position: relative;
			 &:before{
	            content:''; position: absolute; left: 0; right: 0; top: 99%; background: url(../images/material/bg-benefit.png)no-repeat center;
	            width: 100%; height: 101px; z-index: 2;
	        }
			.wrapper{ top: auto; @include transform(translateY(0)); }
			.box-circle{
				figure{
					width: 94px; height: 94px;
					img{ width: 80px; }
				}
			}
		}
		&.slider-direksi-qts{
			padding: 20px 0 60px;
			.wrapper{ margin: 0px; padding: 0px 50px; }
			.text-qoutes{
				p{ font-size: 18px; line-height:30px;   }
			}
			.slider-direksi{
				.list{ margin: 0 15px; }
				figure{ width: 60px; padding-right: 10px; }
				h6{ font-size: 16px; line-height: 24px; }
			}
		}
		&.slide-wave{
			&:before{ display:none; }
			.list{
				figure{ height: 800px; }
			}
		}
		&.form-newsevent{
			.wrapper{ margin: 0px; padding: 0px 50px; }
			 h3{ font-size: 28px; }
		}

	}
	.inner-content{
		padding: 85px 0 70px;
	}
	.video-middle{
		margin: 40px 0 60px;
		.ic-play{ width: 70px; background-size: 100%; }
		.thumb-video{ height: auto; }
	}
	.middle{
		h2{ font-size: 24px; line-height: 34px; }
	}
	.line{ font-size: 16px; }
	.pagging{
		.arr{
			width: 42px; height: 42px;
			&:before{ width:32px; height: 32px; }
		}
	}
	.our-branches{
		padding: 40px 0 60px; margin-top: 70px;
		&:before{
			background:url(../images/material/bg-ourbranches.png)no-repeat center; height: 25px;
		}
	}
	.slider-address{
		margin: 30px -30px 0;
	}
	.wrap-list-catalog{
		.list{ margin-bottom: 10px; padding-bottom: 5px; }
		p{ max-height: 45px; overflow: hidden; }
	}
	.wrap-download{
		margin-top: 40px;
		.ic-dwnload{
			width: 45px; height: 40px;
			img{ width: 20px; }
		}
		.text{
			h5{ font-size:14px; line-height: 22px; }
			small{ font-size: 12px; }
		}
	}
	.slider-gallery{
		margin: 0 0; padding: 0;
		.list{ padding: 0 10px; }
		.slick-arrow{
			width: 42px; height: 42px; z-index: 3;
			&:before{
				width:32px; height: 32px;
			}
		}
	}
	.sec-box-two{
		.bg{
			width: 100%; height: 50%;  background-size: cover; display: none;
			&.rgh{
				top: auto; bottom: 0;
			}
		}
		.left-box,.right-box{
			float: none; display: block; width: 100%; position: relative; padding: 60px 0; position: relative;
	        &:before{
			 	content:'' ;background: #EFF2F6 url(../images/material/bg-fasilitas.png)no-repeat center;
			 	z-index: -1;  background-size: 100%;
			 	position:absolute; left: -90px; right: -90px; top: 0; bottom: 0; width: auto;
	        }
		}
		.right-box{
			&:before{
	       		background: #FFC92E url(../images/material/bg-area.png)no-repeat center;
			}
		}

	}
	.masterplan{ padding: 60px 0; }
	.tabbing-spek{
		table{
			tr{
				td{ font-size: 14px; }
			}
		}
	}
	.slider-address{
		.slick-arrow{
			width: 42px; height: 42px;
			&:before{ width:32px; height: 32px; }
		}
		p{ font-size: 14px; line-height: 22px; }
	}
	.mob-dropdown{
		display: block; cursor: pointer; border-top: 1px solid  #9A9A9A; border-bottom: 1px solid #9A9A9A; padding: 15px 25px;
		 text-align: center;  @include boxSizing(border-box); z-index: 2;

		&:after{
		 	content:''; position: absolute; right: 5px; top: 0; bottom: 0; font-size: 12px; margin: auto 0;  font-weight: 200;
			background: url(../images/material/ic-arr-down.png); width: 10px; height: 6px;
		}
		span{
			position: relative; padding-right:10px; display: block;
			font-size: 14px; font-weight: bold;
		}
	}
	.row-tittle{
		&.news{
	        @include afterclear;
	        .left,.right{ display: block; }
	        .left{ float: left; }
	        .right{ float: right; position: relative; margin-top: 50px; }
	    }
	}
	.filter-news-event{
		display: none; position: absolute; top:100%; right: 0; width: 100%;
		background: #fff; z-index: 2; @include boxShadow(0 0 20px -10px #000);
		a{ font-size: 14px; }
		ul{
			li{
				display: block;text-align: center;  @include boxSizing(border-box); margin: 0;
				padding: 15px 25px; line-height:0; border: none; border-bottom: 1px solid #9A9A9A;
			}
		}
	}
	.middle{ padding-top: 100px; }
	.detail-page{
		padding-top: 50px;
		&:before{ height:300px; }
		.img-detail{
			margin-bottom: 50px; height: 500px;
			img{ height: 100%; object-fit: cover; }
		}
	}
	.slider-gallery{
		.desc{ left: 20px; right: 20px; bottom: 25px; }
		.list{
			&:hover{
				.hide{ height:100px; }
			}
		}
		.hide{
			p{ font-size: 12px; line-height: 20px; height: 65px; overflow: hidden; }
		}
	}
	.wrap-pop{
		.pop-inner{
			.content-pop{ width: 700px; margin:10% auto 0; }
			figure{
				height: 300px;
				img{ height: 100%; }
			}
			.text{ padding: 0; overflow: auto; max-height: 150px; }
			.overflow{  padding: 20px; }
		}
		&.zoomin{
			.pop-inner{
				.content-pop{ width: 800px; }
			}
		}
	}
	.ourproject-landing{
		&:before{ height:370px; }
	}
	.two-box.project .images{ @include transform(scale(1)); }
	.sec-gallery{ padding:95px 0;  }
	footer{
		.top{
			.box{
				margin-right: 35px;
				&:first-child{ width:230px; }
			}
		}
	}
}

@media (min-width: 0px) and (max-width: 1023px) {
	.flex-list {display: -webkit-box;display: -moz-box;display: -ms-flexbox;display: -webkit-flex;display: flex;-webkit-flex-flow: row wrap;flex-wrap:wrap;display:block !important;}
	.mobile-menu{ display: block; }
	.overflow{  }
	.stay-wa a.phone{ display: inline-block; }

	header{
		height: 100px; background: #171717;
		.main-head{
			background: transparent; height: 100%;

			&.open{
				position: relative;
				&:before{
					content:''; position: absolute; left: 0; top: 0; right: 0; background:#171717; height: 100px;
					width: 100%; z-index: 1;
				}
			}
			.wrapper{
				margin: 0 50px;
				nav{
					display: none; position: fixed; top: 0; left: 0; right: 0; background:  url(../images/material/bg-mobile-menu.png) ;
					bottom: 0; overflow: auto;
					ul{
						padding:140px 40px 0;
						li{
							display: block; text-align: left; margin: 0 !important;
							a{
								padding: 10px 0; margin: 0 !important; display:inline-block; font-size: 16px;
								&:before{ display:none; }
							}
							&:nth-child(2){ margin-right: 0; }
							&:nth-child(4){ margin-left: 0; }
							&.logo{ margin: 0 auto !important; display: none; }

							&.hv_child{
								ul{
									position: relative; background: transparent; opacity: 1;
									visibility: visible; left: 0; padding: 0; display: none;
									-webkit-column-count: auto;  -moz-column-count: auto; column-count: auto;
									@include boxShadow(none);
									>li{
										a{ padding: 5px 0 5px 10px; }
									}
								}
							}
						}
					}
				}
				.rh_head{ z-index: 2; }
			}
		}
		.logo-mobile{
			position: absolute; left: 0; right: 0; margin:auto; width: 90px; height: 55px;
			top: 0; bottom: 0; z-index: 99;
		}
	}
	.logo-header{
		a{
			img{ width: 90px; }
		}
	}



	.togglemenu{
        float: left; width: 30px; height:35px; position: relative; cursor: pointer; z-index: 10001;
        margin:40px 0 0px 50px; display: block;
        span{
            width: 100%; height: 3px; background: #FFD223; display: block; position: absolute;
            @include transition(0.2s ease-out all); @include borderRadius(20%); overflow: hidden;
            &:nth-child(1){ top: 0; }
            &:nth-child(2){ top: 8px;  }
            &:nth-child(3){ top: 16px; }
        }
        &.close{
            span{
                background: #000;
                &:nth-child(1){ top: 12px; @include transform(rotate(45deg)); }
                &:nth-child(2){ opacity: 0; }
                &:nth-child(3){ top: 12px; @include transform(rotate(-45deg)); }
            }
        }
    }

	.two-box{
		.lf{ width: 45%; }
		.rh{ padding-left: 20px; }
	}
	.video-middle{
		&.home{
			.thumb-video{ height: auto; }
		}
	}
	.ttl{ font-size: 20px; line-height: 26px; margin-bottom: 10px; }
	.home-slide-section{
		.slider-home{
			.slide-banner{
				.caption-text{
					.txt{
						width: 555px;
						h2{ font-size: 32px; line-height: 40px; margin-bottom: 15px;}
						p{ font-size: 12px; line-height: 20px; font-weight: 200; }
						&.search-result-tittle{
							width: auto;
						}
					}
					&.ourproject{
						.txt{ width: auto; }
					}
				}
				.logo-housing{
					img{ width: 150px; }
				}
			}
		}

	}
	.wrap-dot-bg{
		.dot-slider{
			.dots-project{
				background: none !important;
				&:nth-child(5n+1),
				&:nth-child(5n+2),
				&:nth-child(5n+3),
				&:nth-child(5n+4),
				&:nth-child(5n+5){
					span{
						top: 0; padding: 0 0px 40px 0; font-size: 0;
						&:after{ bottom:0; top: auto; }
					}
				}
				&.slick-center{
					span{
						font-size:16px;
					}
				}

			}

		}
	}
	.home{
		&.slide-wave{

			.desc{ padding: 0; width: 470px; }
			p{ font-size: 12px; line-height: 20px; }
			small{ font-size: 12px; line-height: 20px; margin-bottom: 5px; }
			h3{ font-size: 28px; line-height: 40px; margin-bottom: 10px; }
		}
		&.about{
			padding: 50px 0;
			p{ font-size: 12px; margin-bottom: 20px; }
		}
		&.benefit{
			padding: 40px 0 50px;
			.box-circle{
				padding: 0 20px; @include boxSizing(border-box); position: relative;
				.circle{ margin: 0; }
				h5{ font-size: 14px; margin-bottom: 5px; }
				p{ font-size: 14px;  line-height: 18px; max-height: 56px; }
				.slick-arrow{
					position: absolute; left: 0%; top: 0; bottom: 0; font-size: 0; border: none;
					width: 42px; height: 42px; border:1px solid transparent; @include borderRadius(100%);overflow: hidden;
					margin: auto 0; cursor: pointer; @include transition(.2s all ease-out); background: transparent;
					&:before{
						content:''; position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; @include borderRadius(100%);
						width: 32px; height: 32px; z-index: 2; @include transition(.2s all ease-out);
						background-color: rgba(23,23,23,1);
						background: -moz-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
						background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(23,23,23,1)), color-stop(100%, rgba(92,92,92,1)));
						background: -webkit-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
						background: -o-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
						background: -ms-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
						background: linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
						filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171717', endColorstr='#5c5c5c', GradientType=1 );
						  opacity: 0; visibility: hidden;
				   }
					&:after{
						content: "";background:url(../images/material/ic-arr-right.png) center no-repeat;position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 2;
						@include transition(.2s all ease-out);  @include transform(rotate(180deg));
					}
					&.slick-next{
						left: auto; right:0;
						&:after{
							@include transform(rotate(0deg));
						}
					}
					&:hover{
						border-color: #FCB140;
						&:before{
							background: rgba(255,128,35,1);
							background: -moz-linear-gradient(-45deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
							background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,128,35,1)), color-stop(100%, rgba(252,177,64,1)));
							background: -webkit-linear-gradient(-45deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
							background: -o-linear-gradient(-45deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
							background: -ms-linear-gradient(-45deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
							background: linear-gradient(135deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
							filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8023', endColorstr='#fcb140', GradientType=1 );
							opacity: 1; visibility: visible;
						}
					}
				}
			}
		}
		&.newsevent{
			&:before{ height:225px; position: absolute; }
			.ttl{ font-size: 20px; line-height: 26px; }
		}
		&.slider-direksi-qts{
			.text-qoutes{
				padding: 20px 0 40px;
				p{ font-size: 14px; }
			}
		}
		&.slider-direksi-qts{
			.slider-direksi{
				padding: 0 30px;
				.slick-arrow{
					width: 42px; height: 42px;
					&:before{ width:32px; height: 32px; }
				}
				.list{
					&:before{ left:-16px; }
				}
				h6{ font-size: 15px; line-height: 22px; height: 23px; overflow: hidden; }
			}
		}
		&.form-newsevent{
			padding: 30px 0;
		}
	}
	.form-middle{
		label{ font-size: 12px; }
	}
	.more{
		font-size: 12px;
		&:after{ width:17px; }
		span{
			padding-right: 30px;
			&:after,&:before{ width:14px; height: 14px; margin: auto 0; right: 10px; }
		}
		&.back{
			span{
				padding-left: 30px;
				&:after,&:before{ width:14px; height: 14px; margin: auto 0; left: 10px; }
			}
		}
	}
	.three-box{
		h4{ font-size: 16px; line-height: 18px; max-height: 70px; }
		p{ font-size: 12px; line-height: 16px; font-weight: 400; }
		.text{ padding: 15px 10px; }
		.row-left-right{
		 	left: 10px; right: 10px;
		 	.left{ padding-top: 0; }

		}

		figure{ height: 180px; }

	}
	.date{ font-size:12px; overflow: hidden; width: 39px; white-space: nowrap; display: inline-block; line-height: 22px;    }
	.stay-wa{
		right: 50px;
		a{ width: 40px; height: 40px; }
	}
	.middle{
		h2{ font-size: 20px; line-height: 30px; }
		p{ font-size: 12px; line-height: 20px; }
	}
	.line{ font-size: 14px; line-height: 22px; }

	.video-middle{
		.thumb-video{ height: auto; }
	}
	.row-tittle{
		&.news{
			.left{ width: 40%; }
		}
	}
	.smll-ttl{ font-size: 12px; line-height: 23px; }

	.wrap-list-catalog{
		p{ margin-bottom: 5px; max-height: unset; }
	}
	.wrap-download{ margin-top: 25px; }
	.slider-gallery{
		.list{ padding: 0 5px; }
	}
	.sec-gallery{
		padding: 70px 0 60px;
		.center{ margin-bottom: 20px; }
		.desc{
			left: 10px; right: 10px; bottom: 15px; height: 20px;
			 h4{ font-size: 12px; line-height: 20px; }
		}
		.slider-gallery{
			.hide{
				height: 50px;
				span{ font-size: 14px; line-height: 22px;  font-weight: 400;}
				p{ max-height: 20px; overflow: hidden; display: none;}
			}
		}
	}
	.sec-box-two{
		.text{
			p{ font-size: 12px;line-height: 18px; }
		}
		.wrap-point-box{
			padding: 0 50px; @include boxSizing(border-box);
			.col{ margin: 0 10px;}
			figure{
				display: block; float: none; text-align: center; margin: 0 auto 10px;
			}
			.text{ margin: 0; text-align: center; padding-left: 0; }
			.slick-arrow{
		        position: absolute; left: 0%; top: 0; bottom: 0; font-size: 0; border: none;
		        width: 42px; height: 42px; border:1px solid transparent; @include borderRadius(100%);overflow: hidden;
		        margin: auto 0; cursor: pointer; @include transition(.2s all ease-out); background: transparent;
		        &:before{
		            content:''; position: absolute; left: 0; top: 0; bottom: 0; right: 0; margin: auto; @include borderRadius(100%);
		            width: 32px; height: 32px; z-index: 2; @include transition(.2s all ease-out);
		            background-color: rgba(23,23,23,1);
		            background: -moz-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
		            background: -webkit-gradient(left bottom, right top, color-stop(0%, rgba(23,23,23,1)), color-stop(100%, rgba(92,92,92,1)));
		            background: -webkit-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
		            background: -o-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
		            background: -ms-linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
		            background: linear-gradient(45deg, rgba(23,23,23,1) 0%, rgba(92,92,92,1) 100%);
		            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#171717', endColorstr='#5c5c5c', GradientType=1 );
		      		opacity: 0; visibility: hidden;
		       }
		        &:after{
		            content: "";background:url(../images/material/ic-arr-right.png) center no-repeat;position: absolute;top: 0;left: 0;right: 0;bottom: 0;z-index: 2;
		            @include transition(.2s all ease-out);  @include transform(rotate(180deg));
		        }
		        &.slick-next{
		            left: auto; right:0;
		            &:after{
		                @include transform(rotate(0deg));
		            }
		        }
		        &:hover{
		            border-color: #FCB140;
		            &:before{
		                background: rgba(255,128,35,1);
		                background: -moz-linear-gradient(-45deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
		                background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(255,128,35,1)), color-stop(100%, rgba(252,177,64,1)));
		                background: -webkit-linear-gradient(-45deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
		                background: -o-linear-gradient(-45deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
		                background: -ms-linear-gradient(-45deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
		                background: linear-gradient(135deg, rgba(255,128,35,1) 0%, rgba(252,177,64,1) 100%);
		                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8023', endColorstr='#fcb140', GradientType=1 );
		            	opacity: 1; visibility: visible;
		            }
		        }
		    }
		 }
	}
	.tabbing-spek{
		table{
			tr{
				td{
					font-size: 12px;
					&:first-child{ width:90px; }
				}
			}
		}
		.slide-type{
			width: 50%; margin-top: 55px;
			.border-bg{ height: 320px; }
		}
		.slider-big{
			h5{ font-size: 12px; }
		}
		.slider-small{
			padding: 0 30px 0 60px;
			.list{ margin-right: 15px; }
		}
	}
	.contactus-project{
		.box-form-project{
			@extend .flex-list;
			.left,.right{ float: none; display: block; width: 100%; margin-right: 0; }
			.left{ order: 2; }
			.right{ order: 1; }
		}
	}
	.detail-page{
		&:before{ height:220px; }
		.img-detail{ height: 380px; }
	}
	.slider-address{
		.list{ margin-right:20px;  }
		a{ font-size: 12px; }
		h5{ font-size: 14px; }
	}

	.page100vh{
		h1{ font-size: 180px; line-height: unset; margin-bottom: 5px; }
		h2{ font-size: 70px; }
		h3{ font-size: 42px; }

		.text{ left: 30px; right: 30px; width: auto; }
		&.pageunder{
			.text{
				width: auto; left: 30px; right: 30px;
			}
		}
		figure{
			img{ object-fit: cover; }
		}
		&.pagety{
			.text{ width: auto; margin: 0 40px; }
		}
		.more{ margin-top: 20px; }
	}
	.sec-gallery{
		.slider-gallery{
			.list{
				&:hover{
					.hide{ height:45px; }
				}
			}
		}
	}
	.masterplan{
		.img-plan{ height: auto; }
	}
	.wrap-pop{
		&.zoomin{
			.pop-inner{
				.content-pop{ width: auto; margin: 35% 30px; }
			}
		}
	}
	footer{
		.top{
			p{ font-size: 12px; line-height: 18px; word-break: break-word; }
			a{ font-size: 12px; line-height: 18px; }
			.box{
				width: 150px; margin-right: 20px;
				&:first-child{ width:185px; }
				&:nth-child(2){ width:115px; }
				&.socmed{
					a{ width: 20px; }
				}
			}
		}
	}
}